import React from 'react';

export default function Youtube({ width = '17', height = '12' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 17 12`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.6535 10.1224C16.458 10.8567 15.8829 11.4359 15.1554 11.6328C13.8246 12 8.50326 12 8.50326 12C8.50326 12 3.18023 12 1.85113 11.6478C1.1352 11.4492 0.546887 10.8567 0.349676 10.1224C0 8.78053 0 6 0 6C0 6 0 3.20445 0.349676 1.87761C0.545229 1.14326 1.12029 0.564117 1.84781 0.367177C3.19183 9.53674e-07 8.49994 9.53674e-07 8.49994 9.53674e-07C8.49994 9.53674e-07 13.823 0 15.1521 0.352156C15.8796 0.549096 16.4547 1.12823 16.6502 1.86259C16.9999 3.20445 16.9999 5.98498 16.9999 5.98498C16.9999 5.98498 17.0165 8.78053 16.6535 10.1224ZM6.80625 3.43143V8.57024L11.2327 6L6.80625 3.43143Z'
        fill='#333333'
      />
    </svg>
  );
}
