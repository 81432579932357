import React from 'react';

export default function Facebook({ width = '20', height = '20' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 14 14`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.3342 0H3.76874C1.66526 0 0 1.70908 0 3.76874V9.3342C0 11.4377 1.70908 13.1029 3.76874 13.1029H9.3342C11.4377 13.1029 13.1029 11.3939 13.1029 9.3342V3.81256C13.1468 1.70908 11.4377 0 9.3342 0ZM11.7883 9.37802C11.7883 10.7365 10.6927 11.8321 9.3342 11.8321H3.76874C2.41024 11.8321 1.31468 10.7365 1.31468 9.37802V3.81256C1.31468 2.45406 2.41024 1.3585 3.76874 1.3585H9.3342C10.6927 1.3585 11.7883 2.45406 11.7883 3.81256V9.37802Z'
        fill='#333333'
      />
      <path
        d='M6.57374 3.5498C4.90849 3.5498 3.50616 4.9083 3.50616 6.61738C3.50616 8.28264 4.86466 9.68496 6.57374 9.68496C8.239 9.68496 9.64132 8.32646 9.64132 6.61738C9.64132 4.9083 8.239 3.5498 6.57374 3.5498ZM6.57374 8.23882C5.65347 8.23882 4.90848 7.49383 4.90848 6.57356C4.90848 5.65329 5.65347 4.9083 6.57374 4.9083C7.49401 4.9083 8.239 5.65329 8.239 6.57356C8.19518 7.49383 7.49401 8.23882 6.57374 8.23882Z'
        fill='#333333'
      />
      <path
        d='M9.99193 2.23486C9.50988 2.23486 9.07166 2.62927 9.07166 3.15514C9.07166 3.63718 9.46606 4.07541 9.99193 4.07541C10.474 4.07541 10.9122 3.68101 10.9122 3.15514C10.9122 2.62927 10.474 2.23486 9.99193 2.23486Z'
        fill='#333333'
      />
    </svg>
  );
}
