import React from 'react';

export default function Call({ width = '46', height = '46' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 50 50`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.3173 30.295C33.6844 29.9785 30.9419 28.6072 30.4145 28.3963C29.8871 28.1853 29.4651 28.0798 29.1487 28.7127C28.7268 29.2401 27.6719 30.6114 27.3555 30.9278C27.039 31.3498 26.7226 31.3498 26.0897 31.0333C25.5623 30.7169 23.6636 30.1895 21.4485 28.1853C19.7608 26.7085 18.6004 24.8099 18.284 24.2825C17.9675 23.755 18.284 23.4386 18.495 23.1221C18.7059 22.9112 19.0224 22.4893 19.3388 22.1728C19.4443 22.0673 19.4443 21.9618 19.5498 21.8564C19.6553 21.6454 19.7608 21.4344 19.8662 21.2235C20.0772 20.8015 19.9717 20.4851 19.8662 20.2741C19.7608 19.9577 18.6004 17.2151 18.073 16.0548C17.6511 14.8945 17.1237 15.1055 16.8072 15.1055C16.4908 15.1055 16.0689 15 15.7524 15C15.3305 15 14.6976 15.1055 14.1702 15.7384C13.6428 16.2658 12.166 17.7425 12.166 20.4851C12.166 21.118 12.2715 21.8564 12.4825 22.3838C13.1154 24.3879 14.2757 26.0757 14.4866 26.3921C14.8031 26.814 18.495 32.721 24.2965 35.0417C30.2035 37.3623 30.2035 36.5184 31.2583 36.4129C32.3132 36.3074 34.6338 35.0417 35.1612 33.6704C35.6886 32.2991 35.6886 31.2443 35.4776 30.9278C35.2667 30.7169 34.9502 30.6114 34.3173 30.295Z'
        fill='#4F4F4F'
      />
      <path
        d='M24.4002 47.6998C11.6002 47.6998 1.2002 37.2998 1.2002 24.4998C1.2002 11.6998 11.6002 1.2998 24.4002 1.2998C37.2002 1.2998 47.6002 11.6998 47.6002 24.4998C47.7002 37.2998 37.3002 47.6998 24.4002 47.6998Z'
        stroke='#4F4F4F'
        strokeMiterlimit='10'
      />
    </svg>
  );
}
