import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { customFontsSize } from '../config/modularHouseTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    backgroundColor: '#4F4F4F',
    alignItems: 'center',
    height: '50px',
    '@media (min-width:1921px)': {
      height: '3.5vw',
    },
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  text: {
    color: theme.palette.primary.fon,
    fontSize: '15px',
    lineHeight: '1.4',
    '@media (min-width:1921px)': {
      fontSize: customFontsSize.h6.adaptiv,
    },
  },
  banner:{
    position:'fixed',
    bottom:'0',
    left:'0',
    width: '100%',
    backgroundColor: 'rgb(79 79 79 / 90%)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    color: theme.palette.primary.fon,
    zIndex: 1,
    height:'50px',
    [theme.breakpoints.down('md')]: {
      height:'auto',
    },
  },
  banner_text: {
    fontSize: '2vw',
    fontWeight: '700',
    lineHeight: '1.1',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: theme.palette.primary.fon,
    textAlign: 'center',
    // '@media (min-width:1921px)': {
    //   fontSize: "3.54vw",
    // },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '26px',
      padding: '16px',
      textAlign: 'center',
    },
  },
}));

const Footer = ({lang}) => {
  const classes = useStyles();

  return (
    <Box component='footer' className={classes.root}>
      <Box>
        <Typography className={classes.text}>
        {`© ${new Date().getUTCFullYear()} | ${lang === 'EN' ? 'LLC "Art-studio "Zrobim"' : 'ООО "Арт-студия "Зробим"'}`}
        </Typography>
      </Box>
      {/*
      <Box className={classes.banner}>
        <Typography className={classes.banner_text}>
        {`Black Friday -10% Off`}
        </Typography>
      </Box>
      */}
    </Box>
  );
};
export default Footer;
